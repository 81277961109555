@font-face {
  font-family: 'digital-reg';
  src: url("/assets/fonts/ds_digital/DS-PLAY.ttf"); }

@font-face {
  font-family: 'digital-bold';
  src: url("/assets/fonts/ds_digital/DS-DIGIB.TTF"); }

@font-face {
  font-family: 'goth-black';
  src: url("/assets/fonts/gothic/GOTHAM-BLACK.OTF"); }

@font-face {
  font-family: 'goth-bold';
  src: url("/assets/fonts/gothic/GOTHAM-BOLD.OTF"); }

@font-face {
  font-family: 'goth-medium';
  src: url("/assets/fonts/gothic/GOTHAM-MEDIUM.OTF"); }

@font-face {
  font-family: 'goth-light';
  src: url("/assets/fonts/gothic/GOTHAM-LIGHT.OTF"); }

.content-wrapper {
  overflow-x: hidden;
  overflow-y: hidden; }

.primary-button {
  background-color: #F58A71;
  color: white;
  border: 0px;
  font-family: "goth-light";
  letter-spacing: 1px;
  transition: .4s; }
  .primary-button:hover {
    color: #F58A71;
    background-color: #FFFFFF;
    transition: .4s;
    cursor: pointer; }

.secondary-button {
  background-color: #71CBCC;
  color: white;
  border: 0px;
  font-family: "goth-medium";
  letter-spacing: 1px;
  transition: .4s; }
  .secondary-button:hover {
    color: #556FB5;
    background-color: #FFFFFF;
    transition: .4s;
    cursor: pointer; }

a {
  color: inherit; }
  a:hover {
    text-decoration: none; }

/**MAIN CONTAINER CSS **/
.main-container {
  padding-left: 10%;
  padding-right: 10%; }
  @media only screen and (max-width: 690px) {
    .main-container {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (max-width: 400px) {
    .main-container {
      padding-left: 0%;
      padding-right: 0%; } }

/**NAV CSS **/
.nav-container {
  padding-top: 100px;
  float: right; }
  .nav-container li {
    color: white;
    list-style: none;
    display: inline;
    padding-right: 20px;
    transition: .4s; }
    .nav-container li:hover {
      color: #FCEE23;
      transition: .4s; }
  @media only screen and (max-width: 690px) {
    .nav-container {
      float: none;
      text-align: center;
      padding-right: 0px;
      padding-top: 50px; }
      .nav-container li {
        padding: 5px; } }

.social-icons {
  text-align: center; }
  .social-icons i {
    font-size: 30px;
    color: white;
    padding-right: 15px;
    padding-bottom: 15px;
    transition: .4s; }
    .social-icons i:hover {
      color: #FCEE23;
      transition: .4s;
      cursor: pointer; }

html, body {
  overflow-x: hidden; }
  @media only screen and (min-width: 415px) {
    html, body {
      width: 100%; } }
  @media only screen and (max-width: 414px) {
    html, body {
      max-width: 414px; } }
  @media only screen and (max-width: 375px) {
    html, body {
      max-width: 375px; } }
  @media only screen and (max-width: 320px) {
    html, body {
      max-width: 320px; } }

body {
  background-image: url("/assets/images/DIAL_WEBSITE_RD2_02.png");
  font-family: "goth-medium";
  background-color: #2A93D1;
  background-repeat: no-repeat;
  background-size: cover; }
  @media only screen and (max-width: 690px) {
    body {
      background-size: inherit;
      width: 100%; } }

h1 {
  font-family: "digital-reg";
  color: #FCEE23;
  letter-spacing: 5px;
  font-size: 2.2em; }
  @media only screen and (max-width: 690px) {
    h1 {
      font-size: 17px;
      letter-spacing: 2px; } }

@media only screen and (max-width: 690px) {
  .text-center-mobile {
    text-align: center; } }

.about:hover {
  cursor: pointer; }

#phone-1 {
  position: absolute; }
  #phone-1 img {
    width: 85%; }
  @media only screen and (max-width: 1300px) {
    #phone-1 img {
      width: 70%; } }
  @media only screen and (max-width: 1040px) {
    #phone-1 img {
      width: 50%; } }

#phone-2 {
  position: absolute;
  right: 0;
  margin-top: -180px;
  margin-right: -260px; }
  @media only screen and (max-width: 1300px) {
    #phone-2 {
      margin-top: -160px;
      margin-right: -330px; }
      #phone-2 img {
        width: 70%; } }
  @media only screen and (max-width: 690px) {
    #phone-2 {
      margin-top: -80px;
      margin-right: -410px; }
      #phone-2 img {
        width: 50%; } }
  @media only screen and (max-width: 340px) {
    #phone-2 {
      display: none; } }

#phone-3 {
  margin-left: -253px;
  margin-top: -270px;
  position: absolute; }
  @media only screen and (max-width: 1300px) {
    #phone-3 {
      margin-top: -200px; }
      #phone-3 img {
        width: 70%; } }
  @media only screen and (max-width: 1040px) {
    #phone-3 {
      margin-left: -193px;
      margin-top: -150px; }
      #phone-3 img {
        width: 60%; } }
  @media only screen and (max-width: 340px) {
    #phone-3 {
      margin-left: -233px;
      margin-top: -140px; } }

#phone-4 {
  position: absolute;
  right: 0;
  margin-top: -465px;
  margin-right: -205px; }
  @media only screen and (max-width: 1300px) {
    #phone-4 {
      margin-right: -265px; }
      #phone-4 img {
        width: 70%; } }
  @media only screen and (max-width: 1040px) {
    #phone-4 {
      margin-top: -235px;
      margin-right: -385px; }
      #phone-4 img {
        width: 60%; } }

#phone-5 {
  margin-top: 96px;
  margin-left: -190px;
  position: absolute; }
  #phone-5 img {
    width: 80%; }
  @media only screen and (max-width: 1300px) {
    #phone-5 img {
      width: 70%; } }
  @media only screen and (max-width: 1040px) {
    #phone-5 {
      margin-top: -100px;
      margin-left: -120px; }
      #phone-5 img {
        width: 60%; } }
  @media only screen and (max-width: 500px) {
    #phone-5 {
      margin-top: -200px;
      margin-left: -190px; } }

#phone-6 {
  margin-right: -140px;
  float: right; }
  @media only screen and (max-width: 1300px) {
    #phone-6 {
      margin-right: 0px; }
      #phone-6 img {
        width: 70%; } }
  @media screen and (max-width: 500px) {
    #phone-6 {
      margin-top: 130px;
      margin-right: -270px; } }
  @media screen and (max-height: 785px) {
    #phone-6 {
      margin-top: 300px;
      margin-right: -270px; } }

.main-logo {
  padding-top: 20%;
  text-align: center; }
  .main-logo img {
    width: 90%;
    min-width: 300px;
    max-width: 1440px; }
  @media only screen and (max-width: 690px) {
    .main-logo {
      padding-top: 15%; } }

.blurb {
  color: white;
  font-size: 16px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 14px;
  font-weight: 300;
  font-family: "goth-light";
  letter-spacing: 0.8px; }

.divider {
  text-align: center;
  padding-top: 50px; }
  .divider img {
    width: 15%;
    min-width: 150px; }
  @media only screen and (max-width: 690px) {
    .divider {
      padding-top: 50px; } }

.second-divider {
  padding-top: 55px;
  padding-bottom: 200px; }
  @media only screen and (max-width: 690px) {
    .second-divider {
      padding-bottom: 60px;
      padding-top: 50px; } }

.third-divider {
  padding-bottom: 100px; }

.upcoming {
  padding-top: 40px;
  padding-bottom: 5%; }

@media only screen and (max-width: 690px) {
  .mobile-off {
    display: none; } }

.mobile-on {
  display: none; }
  @media only screen and (max-width: 690px) {
    .mobile-on {
      display: unset; } }

.event-table {
  margin: 0 auto;
  color: white;
  font-size: 16px;
  color: white;
  letter-spacing: 0.8px; }
  .event-table tr td {
    padding-bottom: 20px; }

.dialed {
  margin-right: 10px !important;
  margin-left: 10px !important;
  color: #FCEE23 !important;
  text-align: center; }
  .dialed a {
    background-color: #2396CF;
    padding: 10px 20px; }

@media only screen and (max-width: 690px) {
  .email-header {
    font-size: 25px; } }

.email-login {
  text-align: center;
  margin: 0 auto;
  padding: 0px 13% 0px 13%;
  max-width: 1040px; }
  @media only screen and (max-width: 690px) {
    .email-login {
      padding: 0px 5% 0px 5%; } }

.email {
  width: 100%;
  max-width: 1040px;
  background-color: #0074b7;
  color: white;
  letter-spacing: 0.8px;
  border: 0px;
  height: 50px;
  border-left: 8px solid #004f8d;
  border-top: 8px solid #004f8d;
  margin-top: 30px;
  text-align: center;
  border-radius: 0px; }
  .email::placeholder {
    z-index: 100;
    color: white !important;
    text-align: center;
    letter-spacing: 0.8px;
    background-color: #0074b7; }
  .email::-webkit-input-placeholder {
    color: white !important;
    text-align: center;
    letter-spacing: 0.8px; }
  .email::-webkit-input-placeholder {
    color: white !important;
    text-align: center;
    letter-spacing: 0.8px; }
  .email::-moz-placeholder {
    color: white !important;
    text-align: center;
    letter-spacing: 0.8px; }
  .email::-ms-placeholder {
    color: white !important;
    text-align: center;
    letter-spacing: 0.8px; }
  @media only screen and (max-width: 690px) {
    .email {
      font-size: 12px;
      margin-top: 10px; }
      .email::placeholder {
        font-size: 12px; } }

.submit-email {
  width: 85px;
  height: 25px;
  margin: 0 auto;
  background-color: #FCEE23;
  margin-top: 15px;
  color: #0E74B6;
  border-radius: 20px;
  font-size: 11px;
  border: 0px;
  letter-spacing: 0.8px;
  transition: .4s; }
  .submit-email:hover {
    cursor: pointer;
    transition: .4s;
    color: #FCEE23;
    background-color: #0E74B6; }

.event {
  padding-left: 13%;
  padding-right: 7%;
  padding-top: 10px;
  padding-bottom: 10px; }
  .event div {
    display: inline;
    font-size: 16px;
    color: white;
    letter-spacing: 0.8px; }
    .event div .dialed {
      height: 30px;
      margin-right: 10px;
      margin-left: 10px;
      color: #FCEE23; }
  @media only screen and (min-width: 1600px) {
    .event {
      padding-left: 20%;
      padding-right: 15%; } }
  @media only screen and (max-width: 1200px) {
    .event {
      padding-left: 10%;
      padding-right: 5%; }
      .event div {
        font-size: 14px; } }
  @media only screen and (max-width: 980px) {
    .event {
      padding-left: 0%;
      padding-right: 0%; }
      .event div {
        font-size: 12px; } }
  @media only screen and (max-width: 690px) {
    .event {
      padding-left: 5%;
      padding-right: 5%; }
      .event div {
        display: block;
        font-size: 12px; } }
  @media only screen and (max-width: 400px) {
    .event {
      padding-left: 3%;
      padding-right: 3%; }
      .event div {
        font-size: 12px; } }

@media only screen and (max-width: 690px) {
  .inline-on-mobile {
    padding-bottom: 5px; }
    .inline-on-mobile div {
      display: inline; } }

@media only screen and (max-width: 690px) {
  .float-none-mobile {
    float: none !important; } }

.date, .bit-date {
  color: #98efff !important;
  padding-right: 50px; }
  @media only screen and (max-width: 690px) {
    .date, .bit-date {
      padding: 5px; } }

.tickets {
  background-color: #2f5aa3;
  transition: .4s;
  border: 0px;
  width: 90px;
  font-size: 12px;
  height: 30px; }
  .tickets:hover {
    background-color: white;
    color: #2f5aa3;
    cursor: pointer; }

.description {
  color: white;
  font-size: 16px;
  text-align: center;
  padding-left: 13%;
  padding-right: 13%;
  padding-bottom: 207px;
  font-family: "goth-light";
  max-width: 1040px;
  margin: 0 auto; }
  @media only screen and (max-width: 1200px) {
    .description {
      padding-left: 17%;
      padding-right: 17%; } }
  @media only screen and (max-width: 980px) {
    .description {
      padding-left: 17%;
      padding-right: 17%; } }
  @media only screen and (max-width: 690px) {
    .description {
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 100px; } }

.location {
  padding-right: 50px; }
  @media only screen and (max-width: 690px) {
    .location {
      padding: 5px;
      margin-right: 0px; } }

.dial-it-in-footer {
  margin-top: 380px;
  margin-left: -10%; }
  .dial-it-in-footer img {
    width: 110%; }

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 3.5s;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 3.5s;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 3.5s;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 3.5s;
  animation-timing-function: linear; }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.bit-widget {
  background-color: transparent !important;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.8px;
  width: 83% !important;
  margin: 0 auto; }
  .bit-widget .bit-details, .bit-widget .bit-details .bit-date {
    font-weight: normal !important;
    font-size: 16px !important; }
  .bit-widget .bit-details .bit-venue, .bit-widget .bit-details .bit-location {
    color: #fff; }

.bit-button {
  color: #FCEE23 !important; }

.bit-events-container {
  font-family: "goth-medium"; }

.bit-nav-bar span {
  font-family: "goth-medium";
  color: #FCEE23; }
  .bit-nav-bar span.bit-clickable {
    color: #000000; }
